import { Injectable } from '@angular/core';
import { Stitch, RemoteMongoClient, UserPasswordCredential, StitchUser, UserPasswordAuthProviderClient, StitchServiceError, StitchServiceErrorCode } from 'mongodb-stitch-browser-sdk';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './common.service';
import { User } from '../shared/user/user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private common: CommonService,
    // public dialogRef: MatDialogRef<any>
  ) { }


  client = Stitch.initializeDefaultAppClient('rentametuauto0-jreet');
  db = this.client.getServiceClient(RemoteMongoClient.factory, 'rentametuautoapp').db('rentametuauto');

  // Define the listener
  myAuthListener = {
    onUserAdded: (auth, addedUser: StitchUser) => {
      // console.log('onUserAdded:', addedUser);
    },
    onUserLoggedIn: (auth, loggedInUser) => {
      // console.log('onUserLoggedIn:', loggedInUser.id);
    },
    onActiveUserChanged: (auth, currentActiveUser, previousActiveUser) => {
      // console.log(auth, currentActiveUser, previousActiveUser);
      // this.spinner.show();
      if (!currentActiveUser) {
        this.noLogged();
      }

    },
    onUserLoggedOut: (auth, loggedOutUser) => {
      // console.log('onUserLoggedOut:', loggedOutUser.profile);
    },
    onUserRemoved: (auth, removedUser) => {
      // console.log('onUserRemoved:', removedUser.profile)
    },
    onUserLinked: (auth, linkedUser) => {
      // console.log('onUserLinked:', linkedUser.profile)
    },
    onListenerRegistered: (auth) => {
      this.spinner.show();
      this.check();

    }
  };

  authListener(): void {
    this.client.auth.addAuthListener(this.myAuthListener);
  }

  login(user: string, pass: string): Promise<StitchUser> {
    return this.client.auth.loginWithCredential(new UserPasswordCredential(user, pass));
  }

  logoff(): void {
    this.client.auth.logout()
      .then(() => this.common.toast('success', '', 'Good bye!'))
      .then(() => this.currentUser = {})
      .finally(() => this.common.spinner.hide());
  }

  logoffUser(userId: string): void {
    this.client.auth.logoutUserWithId(userId);
  }

  forgotPassword(email: string): void {
    this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory).callResetPasswordFunction(email, '123456', [])
      .finally(() => {
        this.common.toast('success', '', 'Si la cuenta existe en nuestro sistema recibiras un correo electrónico pronto!');
        this.router.navigate(['login']);
      });
  }

  noLogged(): void {
    this.router.navigate(['/']);
  }

  noAuthenticated(): boolean {
    this.router.navigate(['/']);
    return false;
  }

  check(): void {
    const temp = this.client.auth.user;
    if (temp) {
      this.db.collection('users').findOne({ _id: temp.id }, { projection: { status: 1, isAdmin: 1 } })
        .then((userInfo: User) => {
          this.currentUser = userInfo;
        })
        .catch((err) => { });
    } else {
      this.common.spinner.hide();
    }
  }

  isAdminAndActive(): Promise<boolean> {
    const temp = this.client.auth.user;
    if (temp) {
      return this.db.collection('users').findOne({ _id: temp.id }, { projection: { status: 1, isAdmin: 1 } })
        .then((userInfo: User) => {
          this.currentUser = userInfo;
          return userInfo.isAdmin && userInfo.status === 'Activo' ? true : this.noAuthenticated();
        })
        .catch((err) => this.noAuthenticated());
    } else {
      return Promise.resolve(this.noAuthenticated()).finally(() => this.common.spinner.hide());
    }
  }

  isAuthAndActive(): boolean | Promise<boolean> {
    // this.common.spinner.show('child');
    const temp = this.client.auth.user;
    if (temp) {
      return this.db.collection('users').findOne({ _id: temp.id }, { projection: { status: 1, isAdmin: 1 } })
        .then((userInfo: User) => {
          this.currentUser = userInfo;
          return userInfo.status === 'Activo' ? true : this.noAuthenticated();
        })
        .catch(err => this.noAuthenticated())
        .finally(() => this.common.spinner.hide());
    } else {
      return Promise.resolve(this.noAuthenticated()).finally(() => this.common.spinner.hide());
    }
  }

  isNotAuth(): boolean | Promise<boolean> {
    // this.common.spinner.show('child');
    const temp = !this.client.auth.user;
    if (temp) {
      return Promise.resolve(true).finally(() => this.common.spinner.hide());
    } else {
      this.router.navigate(['/usuarios']);
      return Promise.resolve(false).finally(() => this.common.spinner.hide());
    }
  }

  resetPasword(token: string, tokenId: string, pass: string): Promise<boolean> {

    return this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory).resetPassword(token, tokenId, pass)
      .then(() => true)
      .catch((err: StitchServiceError) => false);
  }

}
