import { Component, OnInit, Inject } from '@angular/core';
import { ErrorStateMatcher, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroupDirective, NgForm, FormControl, Validators, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public terminal: any,
    public dialogRef: MatDialogRef<TerminalComponent>,
    private auth: AuthService,
    private common: CommonService,
  ) { }
  matcher = new MyErrorStateMatcher();
  type: string;
  form = new FormGroup({
    terminalName: new FormControl('', [Validators.required]),
    isDeleted: new FormControl(false, []),
  });

  // Gets the form fields
  get formGroup() { return this.form.controls; }

  ngOnInit() {
    this.type = this.terminal.type;
    if (this.type === 'edit') {
      this.auth.db.collection('terminals').findOne({ _id: this.terminal.id })
        .then(locationInfo => {
          Object.keys(locationInfo).map(key => {
            if (this.formGroup[key]) {
              this.formGroup[key].setValue(locationInfo[key]);
            }
          });
        })
    }

  }

  updateTerminal(): void {
    const tempTerminalObject = {};
    Object.keys(this.formGroup).map(key => tempTerminalObject[key] = this.formGroup[key].value);
    this.auth.db.collection('terminals')
    .findOneAndUpdate({ _id: this.terminal.id }, tempTerminalObject)
    .then(() => this.dialogRef.close());

  }

  createTerminal(): void {
    const tempTerminalObject = {};
    Object.keys(this.formGroup).map(key => tempTerminalObject[key] = this.formGroup[key].value);
    this.auth.db.collection('terminals')
      .insertOne(tempTerminalObject)
      .then(() => this.dialogRef.close());
  }

  deleteTerminal(): void {
    this.auth.db.collection('terminals')
      .deleteOne({ _id: this.terminal.id })
      .then(() => this.dialogRef.close());
  }


}
