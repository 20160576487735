import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  constructor(
    private router: Router,
    private common: CommonService
  ) { }

  ngOnInit() {
    this.common.spinner.hide();
  }

  async goHome() {
    // await this.common.spinner.show();
    setTimeout(() => {
      this.router.navigate(['']);
    }, 700);
  }

}
