import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MenuItems, Menu, MenuStatus } from 'src/app/shared/menu-items/menu-items';
import { MediaMatcher } from '@angular/cdk/layout';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private auth: AuthService,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 960px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout() {
    this.auth.logoff();
  }

  show(menu: Menu) {
    // console.log(this.auth.currentUser);
    switch (menu.status) {
      case MenuStatus.Admin:
        // console.log(`${menu.name} ${this.auth.client.auth.user.id} ${this.auth.currentUser}`);
        return !!this.auth.client.auth.user && !!this.auth.currentUser && !!this.auth.currentUser.isAdmin;
      case MenuStatus.Logged:
        // console.log(`${menu.name} ${!!this.auth.currentUser.id}`);
        return !!this.auth.client.auth.user;
      case MenuStatus.NoLogged:
        // console.log(`${menu.name} ${!this.auth.currentUser.id} ${!this.auth.client.auth.user}`);
        return !this.auth.client.auth.user;
      case MenuStatus.Any:
        return true;
    }

  }

}
