import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastr: ToastrService,
    public spinner: NgxSpinnerService,
  ) {
    this.spinner = spinner;
   }

  toast(type: string, title: string, message: string): void {
    switch (type) {
      case 'error':
        this.toastr.error(message, title);
        break;
      case 'info':
        this.toastr.info(message, title);
        break;
      case 'success':
        this.toastr.success(message, title);
        break;
      case 'warning':
        this.toastr.warning(message, title);
        break;
      default:
        this.toastr.show(message, title);
    }
  }




}