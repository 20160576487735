import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MyErrorStateMatcher } from '../login/login.component';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { SeasonsComponent } from '../seasons/seasons.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-season',
  templateUrl: './season.component.html',
  styleUrls: ['./season.component.scss']
})
export class SeasonComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public season: any,
    public dialogRef: MatDialogRef<SeasonsComponent>,
    private auth: AuthService,
    private common: CommonService,
  ) { }
  matcher = new MyErrorStateMatcher();
  type: string;
  form = new FormGroup({
    seasonName: new FormControl('', [Validators.required]),
    percent: new FormControl('', [
      Validators.required,
      Validators.max(50)
    ]),
    minDays: new FormControl('', [
      Validators.required,
      Validators.max(10)
    ]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    confirmationDays: new FormControl('', [
      Validators.required,
      Validators.max(20)
    ]),
    status: new FormControl('', [Validators.required]),
    isDeleted: new FormControl(false, []),
  });

  // Gets the form fields
  get formGroup() { return this.form.controls; }

  ngOnInit() {
    this.type = this.season.type;
    if (this.type === 'edit') {
      this.auth.db.collection('seasons').findOne({ _id: this.season.id })
        .then(seasonInfo => {
          Object.keys(seasonInfo).map(key => {
            if (this.formGroup[key]) {
              this.formGroup[key].setValue(seasonInfo[key]);
            }
          });
        });
    }

  }

  updateTerminal(): void {
    const tempSeasonObject = {};
    Object.keys(this.formGroup).map(key => tempSeasonObject[key] = this.formGroup[key].value);
    this.auth.db.collection('seasons')
      .findOneAndUpdate({ _id: this.season.id }, tempSeasonObject)
      .then(() => this.dialogRef.close());
  }

  createTerminal(): void {
    const tempSeasonObject = {};
    Object.keys(this.formGroup).map(key => tempSeasonObject[key] = this.formGroup[key].value);
    this.auth.db.collection('seasons')
      .insertOne(tempSeasonObject)
      .then(() => this.dialogRef.close());
  }

  deleteTerminal(): void {
    this.auth.db.collection('seasons')
      .deleteOne({ _id: this.season.id })
      .then(() => this.dialogRef.close());
  }


}
