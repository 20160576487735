import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, ErrorStateMatcher, throwToolbarMixedModesError, MatDialogRef } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserPasswordAuthProviderClient, StitchRequestError } from 'mongodb-stitch-browser-sdk';
import { CommonService } from 'src/app/services/common.service';
import { User } from '../../shared/user/user';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public employee: any,
    public dialogRef: MatDialogRef<UserComponent>,
    private auth: AuthService,
    private common: CommonService,
  ) { }

  matcher = new MyErrorStateMatcher();
  type: string;
  form = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    fullName: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.min(10000),
      Validators.max(99999)]),
    dateOfBirth: new FormControl('', [Validators.required]),
    picture: new FormControl('', [Validators.required]),
    companyName: new FormControl('', []),
    status: new FormControl('Activo', [Validators.required]),
    isDeleted: new FormControl(false, []),
  });

  // Gets the form fields
  get formGroup() { return this.form.controls; }

  ngOnInit() {
    this.type = this.employee.type;
    if (this.type === 'edit') {
      this.auth.db.collection('users').findOne({ _id: this.employee.id }).then((employeeInfo: User) => {
        Object.keys(employeeInfo).map(key => {
          if (this.formGroup[key]) {
            this.formGroup[key].setValue(employeeInfo[key]);
          }
        });
      });
    }

  }

  updateEmployee(id: string | null): void {
    const tempUserObject: User = {};
    this.formGroup.fullName.setValue(this.formGroup.firstName.value + ' ' + this.formGroup.lastName.value);
    Object.keys(this.formGroup).map(key => tempUserObject[key] = this.formGroup[key].value);
    delete tempUserObject._id;
    tempUserObject.isDeleted = tempUserObject.status === 'Eliminado';
    this.auth.db.collection('users').findOneAndUpdate({ _id: id || this.employee.id }, { $set: tempUserObject }).then(() => this.dialogRef.close());
  }

  createEmployee(): void {
    const email = this.formGroup.email.value;
    const password = '11111111';
    const currentUser = this.auth.client.auth.user.id;
    let tempUser: string;
    this.auth.client.auth
      .getProviderClient(UserPasswordAuthProviderClient.factory)
      .registerWithEmail(email, password)
      .then(() => this.auth.login(email, password))
      .then(() => tempUser = this.auth.client.auth.user.id)
      .then(() => this.auth.logoffUser(this.auth.client.auth.user.id))
      .then(() => this.auth.client.auth.switchToUserWithId(currentUser))
      .then(() => this.updateEmployee(tempUser))
      .then(() => this.common.spinner.hide())
      .then(() => this.dialogRef.close())
      .catch((err) => {
        if (err.errorCode && err.errorCode === 46) {
          this.form.get('email').setErrors({ exist: true });
          this.common.toast('error', 'Error', 'Usuario ya existe.');
        }
      });
  }

}
