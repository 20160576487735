import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Rentame Tu Auto';
  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) {
    // this.spinner.show();
    this.auth.authListener();
  }
}
