import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { StitchClientError } from 'mongodb-stitch-browser-sdk';
import { CommonService } from 'src/app/services/common.service';
import { ErrorStateMatcher } from '@angular/material';
import { Router } from '@angular/router';

// Error when invalid control is dirty or touched
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  public form: FormGroup;

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    private common: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['hello@jaimemeza.com', Validators.compose([Validators.required, Validators.email])],
      password: ['12121212', Validators.compose([Validators.required])]
    });
    if (this.auth.client.auth.isLoggedIn) {
      this.router.navigate(['buscar']);
    }
  }

  onSubmit() {
    if (this.form.valid === true) {
      this.auth.login(this.form.get('email').value, this.form.get('password').value)
        .then(() => this.auth.isAuthAndActive())
        .then(isActive => {
          if (!isActive) {
            throw { errorCode: 45 };
          }
        })
        // .then(() => this.common.toast('success', '', 'Welcome!'))
        .then(() => this.router.navigate(['/buscar']))
        .catch((error: StitchClientError) => {
          switch (error.errorCode) {
            case 46:
              this.form.get('password').setErrors({ mismatch: true });
              this.common.toast('error', '', 'La combinación de correo y contraseña no es válida');
              break;
            case 45:
              this.common.toast('warning', '', 'Esta cuenta se cuentra suspendida');
              break;
          }
        })
        .finally(() => this.common.spinner.hide());
    }
  }

}
