import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AuthGuard } from './guards/auth.guard';
import { SearchComponent } from './components/search/search.component';
import { AdminGuard } from './guards/admin.guard';
import { NoauthGuard } from './guards/noauth.guard';

const routes: Routes = [
  {
    path: '',
    // canActivate: [AuthGuard],
    component: NavbarComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard]
      },
      {
        path: 'vehiculos',
        loadChildren: () => import('./components/vehicles/vehicles.module').then(m => m.VehiclesModule),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard]
      },
      {
        path: 'ordenes',
        loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard]
      },
      {
        path: 'servicios',
        loadChildren: () => import('./components/services/services.module').then(m => m.ServicesModule),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard]
      },
      {
        path: 'temporadas',
        loadChildren: () => import('./components/seasons/seasons.module').then(m => m.SeasonsModule),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard]
      },
      {
        path: 'terminales',
        loadChildren: () => import('./components/terminals/terminals.module').then(m => m.TerminalsModule),
        canLoad: [AdminGuard],
        canActivate: [AdminGuard]
      },
      {
        path: 'salir',
        loadChildren: () => import('./components/logoff/logoff.module').then(m => m.LogoffModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'buscar',
        component: SearchComponent,
      },
      {
        path: 'olvidar',
        loadChildren: () => import('./components/forgot/forgot.module').then(m => m.ForgotModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: '404',
    component: NotfoundComponent,
    pathMatch: 'full'
  },
  { path: 'reset/:id', loadChildren: () => import('./components/confirm/confirm.module').then(m => m.ConfirmModule) },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
