import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { NgxSpinnerModule } from 'ngx-spinner';

import { MaterialModule } from './material-module';

import { SharedModule } from './shared/shared.module';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { MAT_DATE_LOCALE } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ToastrModule } from 'ngx-toastr';
import { OrderComponent } from './components/order/order.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { UserComponent } from './components/user/user.component';
import { SeasonComponent } from './components/season/season.component';
import { ServiceComponent } from './components/service/service.component';
import { SearchComponent } from './components/search/search.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TerminalComponent } from './components/terminal/terminal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NotfoundComponent,
    OrderComponent,
    VehicleComponent,
    UserComponent,
    SeasonComponent,
    ServiceComponent,
    SearchComponent,
    SidebarComponent,
    TerminalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    SharedModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    })
  ],
  entryComponents: [
    SeasonComponent,
    UserComponent,
    VehicleComponent,
    OrderComponent,
    TerminalComponent,
    ServiceComponent
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE, useValue: 'es-ES'
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
