import { Injectable } from '@angular/core';
export enum MenuStatus {
  Admin = 3,
  Logged = 2,
  NoLogged = 1,
  Any = 0
}

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Separator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  status: MenuStatus;
  state: string;
  name: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    status: 0,
    state: 'buscar',
    name: 'Buscar',
    icon: 'search',
  },
  {
    status: 3,
    state: 'ordenes',
    name: 'Ordenes',
    icon: 'list_alt',
  },
  {
    status: 3,
    state: 'usuarios',
    name: 'Usuarios',
    icon: 'person',
  },
  {
    status: 3,
    state: 'vehiculos',
    name: 'Vehiculos',
    icon: 'drive_eta',
  },
  {
    status: 3,
    state: 'servicios',
    name: 'Servicios',
    icon: 'view_carousel',
  },
  {
    status: 3,
    state: 'temporadas',
    name: 'Temporadas',
    icon: 'date_range',
  },
  {
    status: 3,
    state: 'terminales',
    name: 'Terminales',
    icon: 'location_city'
  },
  {
    status: 2,
    state: 'salir',
    name: 'Salir',
    icon: 'exit_to_app'
  },
  {
    status: 1,
    state: 'login',
    name: 'Login',
    icon: 'exit_to_app'
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
